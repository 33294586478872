<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper" v-on:click.self="$emit('close')">
				<div class="modal-container" :style="style">
					<span class="close" v-on:click="$emit('close')">&times;</span>
					<slot></slot>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	props: {
		size: {
			type: String,
			default: 'large'
		}
	},
	computed: {
		containerWidth() {
			return this.size === 'large'
				? '75vw'
				: this.size === 'medium'
				? '50vw'
				: this.size === 'small'
				? '33vw'
				: '25vw';
		},
		containerHeight() {
			return this.size === 'large'
				? '75wh'
				: this.size === 'medium'
				? '50wh'
				: this.size === 'small'
				? '33wh'
				: '25wh';
		},
		style() {
			return `width: ${this.containerWidth}; height: ${this.containerHeight};`;
		}
	}
};
</script>

<style lang="scss">
.modal-mask {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
	.modal-wrapper {
		display: table-cell;
		vertical-align: middle;
		.modal-container {
			width: 75vw;
			height: 75vh;
			background-color: white;
			position: fixed;
			top: 0;
			left: 0;
			transform: translate(calc(50vw - 50%), calc(50vh - 50%));
			.close {
				position: absolute;
				top: 0.5rem;
				right: 0.5rem;
				display: block;
				font-size: 0;
				height: 30px;
				width: 30px;
				&:before,
				&:after {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 3px;
					height: 20px;
					background-color: $text-color;
					transform: rotate(45deg) translate(-50%, -50%);
					transform-origin: top left;
					transition: all 420ms;
					content: '';
				}
				&:after {
					transform: rotate(-45deg) translate(-50%, -50%);
				}
			}
			@media screen and (max-width: 1100px) {
				width: 95vw !important;
				max-width: 95vw !important;
			}
		}
	}
}
.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
</style>
